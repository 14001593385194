import React from 'react'
import './Section1.css'
import img1 from "../../assets/section1-img1.webp";


const Section1 = () => {
  return (
    <>

    <div className='section1'>
        <div className="section1-content container">
          <div className='section1-left'>
                <h1>What We Do</h1> <br />
                <h2>Substance Abuse Detox and Addiction Treatment Above and Beyond the Norms</h2><br />
                <hr />
                <p>
                At 20th Street Recovery, we believe that true healing begins by addressing both the mind and body. Our holistic addiction treatment approach goes beyond traditional mental health care, offering personalized treatment plans that restore physical, mental, and spiritual well-being.
                <br /> <br />
                We utilize non-invasive, evidence-based methods to relieve acute symptoms that may hinder progress in addiction recovery. Our therapies are consistently applied and closely monitored by our expert clinical team to ensure lasting results.<br /> <br />Unlike conventional substance abuse treatment programs, we focus on whole-person healing—helping individuals achieve long-term sobriety by integrating physical health, mental wellness, and spiritual balance into the recovery journey.
                </p>
          </div>

          <div className='section1-right'>
            <img src={img1} alt="Calm man in front of the sea" loading="lazy" />
          </div>

        </div>
    </div>
    </>
  )
}

export default Section1
