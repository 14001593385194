import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState("Submit");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const sendEmail = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setButtonText("Sending...");

        try {
            await emailjs.sendForm(
                'service_bdfl0ie', 
                'template_a10usvx', 
                form.current, 
                'CLcHWAKSemVMd1_sU'
            );
            setSubmissionStatus("Message Sent Successfully!");
            setButtonText("Message Sent");
            e.target.reset();
        } catch (error) {
            console.error("EmailJS Error:", error);
            setSubmissionStatus("Failed to send message. Try again.");
            setButtonText("Submit");
        }

        setTimeout(() => {
            setButtonText("Submit");
            setSubmissionStatus(null);
        }, 3000);
        setIsSubmitting(false);
    };

    return (
        <div className="contactform">
            <div className="contact-from-header contact2-container">
                <h1>DO YOU NEED URGENT HELP?</h1>
                <p>Our Addiction and Recovery Support team is available to assist you.</p>
            </div>

            <section className="container">
                <div className="footer_get_touch_inner">
                    <div className="get_form_inner">
                        <div className="get_form_inner_text">
                            <h3>Get In Touch</h3>
                            <p><i>We'll keep your contact information strictly confidential.</i></p><br />
                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="inputs">
                                <input 
                                    type="text" 
                                    name="name" 
                                    placeholder="Your Name" 
                                    required 
                                    pattern="[A-Za-z\s]+" 
                                    title="Only letters and spaces allowed"
                                /><br />
                                <input 
                                    type="email" 
                                    name="email" 
                                    placeholder="Your Email" 
                                    required 
                                /><br />
                                <input 
                                    type="tel" 
                                    name="phone" 
                                    placeholder="Your Phone" 
                                    required 
                                    pattern="[0-9+\s-]+" 
                                    title="Only numbers, spaces, +, and - allowed"
                                /><br />
                                <input 
                                    type="text" 
                                    name="subject" 
                                    placeholder="Subject" 
                                    required 
                                /><br />
                            </div>
                            <div>
                                <textarea name="message" placeholder="How can we help?" cols="30" rows="10" required></textarea>
                                <input type="submit" value={buttonText} disabled={isSubmitting} />
                            </div>
                        </form>

                        {submissionStatus && <p className="submission-message">{submissionStatus}</p>}
                    </div>
                </div>
            </section>

            <div className="contact-cards-container">
                <div className="get_form_inner2">
                    <h2>CALL US</h2>
                    <p><AiOutlinePhone /> <a href="tel:3235792196">+1 (323) 579-2196</a></p>
                </div>
                <div className="get_form_inner2">
                    <h2>EMAIL</h2>
                    <p><AiOutlineMail /> info@20thstreetdetox.biz </p><br />
                </div>
                <div className="get_form_inner2">
                    <h2>LOCATION</h2>
                    <p><BsFillPinMapFill /> 1045 20th St, Los Angeles CA 90011</p>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
