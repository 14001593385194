import React from 'react'
import Fade from 'react-reveal/Fade';
import './Conditions.css'

import { Link } from 'react-router-dom'
import { Link as LinkRoll } from 'react-scroll'


import ConditionsLogos from './ConditionsLogos'

import ALCOHOL from '../../assets/alcohol.webp'
import COCAINE from '../../assets/cocaine.webp'
import FENTANYL from '../../assets/fentanyl.webp'
import HEROIN from '../../assets/heroin.webp'
import KRATOM from '../../assets/kratom.webp'
import MDMA from '../../assets/mdma.webp'
import METH from '../../assets/meth.webp'
import OPIATE from '../../assets/opiate.webp'
import OPIOID from '../../assets/opioid.webp'
import OXYCODONE from '../../assets/oxycodone.webp'
import PRESCRIPTIONS from '../../assets/prescription.webp'
import XANAX from '../../assets/xanax.webp'


const Conditions = () => {
    return (
        <>


<div className='conditions-bg'>

<div className='conditions'>

<div className='condition-icons-header container'>
    <h1>Detox Program Options and Available Services</h1>
    <p>At 20th Street Recovery Treatment Center, we offer medically supervised detox programs designed to ease withdrawal symptoms in a safe and supportive environment. Our addiction treatment specialists provide evidence-based care, including behavioral therapy and relapse prevention strategies, to help individuals achieve long-term sobriety. With compassionate support and personalized recovery plans, we empower you to overcome substance abuse and reclaim a healthier, addiction-free life.</p>
</div>



<div className='ConditionsLogosContainer container'>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={ALCOHOL} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/alcohol">
            <button>ALCOHOL ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={COCAINE} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/cocaine">
            <button>COCAINE ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={FENTANYL} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/fentanyl">
            <button>FENTANYL ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={HEROIN} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/heroin">
            <button>HEROIN ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={KRATOM} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/kratom">
            <button>KRATOM ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={MDMA} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/mdma">
            <button>MDMA ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={METH} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/meth">
            <button>METH ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={OPIATE} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/opiate">
            <button>OPIATE ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={OPIOID} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/opioid">
            <button>OPIOID ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={OXYCODONE} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/oxycodone">
            <button>OXYCODONE ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={PRESCRIPTIONS} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/prescriptions">
            <button>PRESCRIPTIONS ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>

<Fade bottom>
<div className='conditions-item'>
    <ConditionsLogos Image={XANAX} />
<div className='conditions-btn'>
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/xanax">
            <button>XANAX ADDICTION</button>
        </Link>
    </LinkRoll>
</div>
</div>
</Fade>
{/* 














<Fade top>
<div className='conditions-icons'>
<ConditionsLogos Image={c} />
    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
        <Link to="/xanax">
            <p>XANAX</p>
        </Link>
    </LinkRoll></div>
</Fade> */}

</div>

</div>

</div>

        </>
    )
}

export default Conditions

