import React from 'react'
import './MissionHome.css'

const MissionHome = () => {
  return (
    <>
        <div className='mission-home'>
            <div className='mission-home-content container'>
                <h1>Our Mission</h1>
                <p>Our mission at 20th Street Recovery is to provide a quiet, safe, and supportive environment where individuals can begin their journey to lasting sobriety. We believe that reducing the prevalence of substance use disorders and addiction starts with personalized, evidence-based treatment. By empowering individuals to take an active role in their recovery, we help them achieve lasting wellness and an improved quality of life.</p>

                <h2>
                "Healing Begins Here at 20th Street Rehab."
                </h2>

            </div>

        
        </div>

    
    </>
  )
}

export default MissionHome