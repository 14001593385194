import React from 'react'
import './Section2.css'
import Circle1 from '../../assets/circle1.png'
import Circle2 from '../../assets/circle2.png'
import Circle3 from '../../assets/circle3.png'
import Circle4 from '../../assets/circle4.png'
import Circle5 from '../../assets/circle5.png'
import Circle6 from '../../assets/circle6.png'

const Section2 = () => {
  return (
    <>
        <div className='section2'>

                <div className='section2-header container'>
                    <h1>Treatment Planning for Long-Lasting Recovery</h1>
                    <p>We work with you to create a personalized treatment plan based on the information you share with us at intake, during your bio-psych-social assessment, and through ongoing individual sessions and family sessions where we help you identify a few key factors about your situation.</p>
                </div>

            <div className='section2-content container'>



{/* ---------------------------- FLEX 1 --------------------------- */}
{/* 1 */}

                <div className='section2-flex'>
                    <div className='section2-left'>
                    <div className='section2-left-content'>
                            <div className='text'>
                                <h1>Comprehensive Addiction Assessment</h1>
                                <p>
                                    <ul>
                                        <li>During intake, we conduct a detailed bio-psychosocial assessment to evaluate your substance use disorder (SUD), mental health, medical history, and personal circumstances.</li>
                                        <li>This assessment helps create a customized recovery plan tailored to your unique needs.</li>
                                    </ul>
                                </p>
                            </div>
                            
                            <div className='circle'>
                                <img src={Circle1} alt="Helping Hands Sign" loading="lazy" />
                            </div>
                        </div>
                    </div>

{/* 2 */}
                    <div className='section2-right section2-flex'>
                        <div className='section2-right-content'>
                            <div className='text'>
                                <h1>Individualized Treatment Planning</h1>
                                <p>
                                    <ul>
                                        <li>We develop a personalized addiction treatment plan based on your assessment, including goals and the appropriate level of care (inpatient rehab, outpatient rehab, or dual diagnosis treatment).</li>
                                        <li>Evidence-based therapies such as cognitive-behavioral therapy (CBT) and medication-assisted treatment (MAT) may be included.</li>
                                    </ul>
                                </p>
                            </div>
                            
                            <div className='circle'>
                                <img src={Circle2} alt="Support Group sign" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>

{/* ------------------------------- FLEX 2 ------------------------- */}
{/* 3 */}

            <div className='section2-flex'>
                    <div className='section2-left2'>
                        <div className='section2-left-content2'>
                            <div className='circle2'>
                                <img src={Circle3} alt="Replapse sign" loading="lazy" />
                            </div>
                            <div className='text'>
                                <h1>Medical Detox & Behavioral Therapy</h1>
                                <p>
                                    <ul>
                                        <li>If needed, we provide medical detox under expert supervision to manage withdrawal symptoms safely.</li>
                                        <li>You participate in individual therapy, group therapy, and holistic addiction treatment to address both physical and emotional healing.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>

{/* 4 */}
                    <div className='section2-right2'>
                        <div className='section2-right-content2'>
                            <div className='circle2'>
                                <img src={Circle4} alt="Family" loading="lazy" />
                            </div>

                            <div className='text2'>
                                <h1>Family Therapy & Support System</h1>
                                <p>
                                    <ul>
                                        <li>We integrate family therapy for addiction recovery to rebuild trust and improve communication with loved ones</li>
                                        <li>Strengthening your support network is key to maintaining long-term sobriety.</li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


{/* --------------------- FLEX 3 ------------------------ */}
{/* 5 */}

<div className='section2-flex'>
                    <div className='section2-left'>
                    <div className='section2-left-content'>

                            <div className='text'>
                                <h1>Relapse Prevention & Coping Strategies</h1>
                                <p>
                                    <ul>
                                        <li>You learn essential relapse prevention techniques, coping mechanisms, and life skills training to manage stress and avoid triggers.</li>
                                        <li>Our specialists guide you in developing a sober lifestyle with healthy routines and mindfulness practices.</li>
                                    </ul>
                                </p>
                            </div>
                            
                            <div className='circle'>
                                <img src={Circle5} alt="Progress sign" loading="lazy" />
                            </div>
                        </div>
                    </div>

{/* 6 */}
                    <div className='section2-right'>
                        <div className='section2-right-content'>
                            <div className='text'>
                                <h1>Ongoing Aftercare & Long-Term Recovery</h1>
                                <p>
                                    <ul>
                                        <li>After primary treatment, we provide addiction aftercare planning, including sober living options, 12-step programs, alumni support, and outpatient rehab.</li>
                                        <li>Continued mental health counseling and addiction recovery coaching help you sustain lifelong sobriety.</li>
                                    </ul>
                                </p>
                            </div>
                            
                            <div className='circle'>
                                <img src={Circle6} alt="Medical Sign" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </>
    
  )
}

export default Section2